<template>
  <div>
    <div class="header">
      <img
        style="height: 40px; width: 40px; margin: 10px 10px 10px 16px"
        src="../../assets/img/chart/chartlogo.png"
        alt=""
      >
      <div>
        <p class="header-title">
          VLLSHOP
        </p>
        <p class="header-subtitle">
          Vllshop-全球跨境电商独立站首选品牌
        </p>
      </div>
    </div>
    <div class="body">
      <div>
        <div class="mbox animated slideInRight">
          <template v-if="showOffForm">
            <div ref="mbody" class="msg-body">
              <div
                v-for="(item, index) in recordList"
                :key="index"
                class="msg"
                :class="item.self ? 'reversal' : 'noreversal'"
              >
                <div
                  class="msg-avatar"
                  :style="item.self ? 'margin-left:10px' : 'margin-right:10px'"
                >
                  <el-avatar
                    v-show="!item.self"
                    src="https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png"
                  />
                </div>
                <div>
                  <div v-show="!item.self" style="text-align: left">
                    {{ item.userName }}
                  </div>
                  <div
                    class="msg-content"
                    :style="item.self ? 'background-color: #576C8A;color:#fff;margin-left: 52px;' : 'margin-right: 52px;background-color: #E2E9FC'"
                  >
                    {{ item.text }}
                  </div>
                </div>
              </div>
            </div>
            <div class="msg-foot">
              <el-input v-model="msgContent" class="inputcla" :rows="5" show-word-limit maxlength="200" style="margin-right: 5px;" type="textarea" placeholder="请输入信息" @keyup.enter.native="sendSingleMsg()" />
              <div style="align-self: flex-end;">
                <el-button class="sendbtn" type="primary" @click="sendSingleMsg()">
                  发送
                </el-button>
              </div>
            </div>
          </template>
          <chatFrom v-else :newwindows="true" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import '@/docs/jmessage-sdk-web.2.6.0.min'
import { Snowflake } from '@/docs/Snowflake'
import { getChartCustomer, jiGuangconfig, jiGuangRecord } from '@/api/register' // getChartCustomer, addPhone
import { getChartKey, setChartKey } from '@/utils/cookie'
import chatFrom from '@/components/chat/form'

export default {
  components: {
    chatFrom
  },
  data() {
    return {
      JIM: '',
      recordList: [],
      msgContent: '',
      chooseName: '',
      selfInfo: {},
      appkey: '',
      showOffForm: true // 是否显示离线表单
    }
  },
  created() {
    // eslint-disable-next-line no-undef
    this.JIM = new JMessage({
      debug: true
    })
    // 获取极光配置
    jiGuangconfig().then((res) => {
      if (res.code !== 401) {
        this.appkey = res.data.appkey
        this.Jginit(res.data)
      }
    })

    // 获取客服
    getChartCustomer().then(res => {
      const customer = res.data
      this.chooseName = customer.name
    })
  },
  destroyed() {
    this.JIM.loginOut()
  },
  methods: {
    // 发送信息
    sendSingleMsg() {
      const that = this
      // 空消息不允许发送
      if (!that.msgContent) {
        return
      }
      // TODO 发送信息
      this.JIM.sendSingleMsg({
        target_username: that.chooseName,
        appkey: that.appkey,
        content: that.msgContent,
        no_offline: false,
        no_notification: false,
        // 'custom_notification':{'enabled':true,'title':'title','alert':'alert','at_prefix':'atprefix'}
        need_receipt: true
      })
        .onSuccess(function(data, msg) {
          console.log(
            'success data:' + JSON.stringify(data) + '发送成功' + msg
          )
          // 将信息放在聊天list
          that.recordList.push({ text: that.msgContent, self: true })
          that.$nextTick(function() {
            that.$refs.mbody.scrollTop =
              that.$refs.mbody.scrollHeight - that.$refs.mbody.clientHeight
          })
          that.msgContent = ''
        })
        .onFail(function(data) {
          console.log('error:' + JSON.stringify(data) + '发送失败')
        })
    },
    // 初始化极光
    Jginit(data) {
      const that = this
      that.JIM.init({
        appkey: data.appkey,
        random_str: data.random_str,
        signature: data.signature,
        timestamp: data.timestamp,
        flag: data.flag
      })
        .onSuccess(function(data) {
          console.log('初始化: success:' + JSON.stringify(data))
          // 登录极光
          that.initAfter()
        })
        .onFail(function(data) {
          console.log('初始化: error:' + JSON.stringify(data))
        })
    },
    initAfter() {
      // 获取用户
      const abcuser = getChartKey()
      // 如果之前没有登录过极光
      if (!abcuser) {
        // 生成一个随机ID，并保存
        const snowflake = new Snowflake(1n, 1n, 0n)
        const tempIds = snowflake.nextId()
        // 保存
        setChartKey(tempIds)
      }
      this.selfInfo.username = getChartKey()
      this.selfInfo.password = getChartKey()
      this.loginJIM({
        username: this.selfInfo.username,
        password: this.selfInfo.password
      })
      // 对方（客服）必须存在
      // this.chooseName = 'deng' // this.customer.name // 'xuqijin110' // item.name
    },
    // 极光登录
    loginJIM(userInfo) {
      const that = this
      const username = userInfo.username
      const password = userInfo.password
      return new Promise((resolve, reject) => {
        const JIM = that.JIM
        if (JIM.isLogin()) {
          // console.log('极光IM已经登录，无需再登录')
          resolve('success')
          return
        }
        // 登录
        JIM.login({
          'username': username,
          'password': password
        }).onSuccess(function(data) {
          // console.log('极光IM登录成功')
          that.getSelfInfo()
        }).onFail(function(data) {
          // 如果用户不存在，则注册账号
          if (data.code === 880103) {
            const nickname = userInfo.username
            JIM.register({
              'username': username,
              'password': password,
              'nickname': nickname,
              'extras': {
                'avatar': '../assets/img/avatar/1.png'
              }
            }).onSuccess(function(data) {
              // console.log('极光IM注册成功')
              // 登录
              JIM.login({
                'username': username,
                'password': password
              }).onSuccess(function(data) {
                // console.log('极光IM登录成功')
                that.getSelfInfo()
              }).onFail(function(data) {
                // console.log(data)
                resolve('success')
              })
            }).onFail(function(data) {
              // console.log('JIM -注册失败' + data)
              resolve('success')
            })
          } else if (data.code === 880109 || data.code === 880107) {
            // console.log(data)
          } else {
            // console.log('极光IM登录失败' + data)
            // console.log('username,password: ' + username + ',' + password)
            resolve('success')
          }
        })
      })
    },
    getSelfInfo() {
      const that = this
      that.JIM.getUserInfo({
        'username': that.chooseName
      }).onSuccess(function(data) {
        // console.log('客服的信息:' + JSON.stringify(data))
        that.loginJIMAfter() // 确认客服用户存在后的操作
      }).onFail(function(data) {
        // const aChild = document.createElement('div')
        // aChild.innerText = '客服人员暂时没有上线哦、如有需要请您留言'
        // that.$refs.mbody.appendChild(aChild)
        that.showOffForm = false
        // that.$message.warning('客服人员暂时没有上线哦、如有需要请您留言')
        // console.log('error:' + JSON.stringify(data))
      })
    },
    loginJIMAfter() {
      const that = this
      that.JIM.onMsgReceive(function(data) {
        that.recordList.push({ text: data.messages[0].content.msg_body.text, self: false, userName: that.chooseName })
        that.$nextTick(function() {
          that.$refs.mbody.scrollTop = that.$refs.mbody.scrollHeight - that.$refs.mbody.clientHeight
        })
        data = JSON.stringify(data)
        // console.log('接受信息:' + data)
      })
      that.JIM.onDisconnect(function() {
        // console.log('【disconnect】')
      }) // 异常断线监听
      // 参数:要聊的人
      that.getJiGuangRecord({ name: that.chooseName })
    },
    // 获取用户聊天记录
    getJiGuangRecord(c_item) {
      const that = this
      // console.log('9', c_item)
      const obj = {
        endTime: that.$moment().format('YYYY-MM-DD HH:mm:ss'),
        beginTime: that.$moment().subtract(7, 'days').format('YYYY-MM-DD HH:mm:ss'),
        userName: c_item.name
      }
      jiGuangRecord(obj).then((res) => {
        const obj = JSON.parse(res.data) // res.data.messages
        const list = obj.messages
        // console.log(obj.messages[obj.messages.length - 1])
        that.recordList = []
        for (let index = 0; index < list.length; index++) {
          const element = list[index]
          if (c_item.name === element.from_id && element.target_id === that.selfInfo.username) {
            // 别人发的，在右边
            that.recordList.push({ text: element.msg_body.text, self: false, userName: c_item.name })
          } else if (element.from_id === that.selfInfo.username && element.target_id === c_item.name) {
            // 如果是自己发送的，在右边
            that.recordList.push({ text: element.msg_body.text, self: true })
          }
        }
        // console.log(that.recordList, 'kkkk')
        that.$nextTick(function() {
          that.$refs.mbody.scrollTop =
                that.$refs.mbody.scrollHeight - that.$refs.mbody.clientHeight
        })
      })
    }

  }
}
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  background: #002858;
  height: 60px;
  color: #fff;
  align-items: center;
}
.header-title {
  text-align: left;
  font-size: 16px;
}
.header-subtitle {
  font-size: 12px;
}
// ----------------------------
.mbox {
  /* position: fixed;
  right: 0px;
  bottom: 10px; */
  box-sizing: border-box;
  background-color: rgb(255, 255, 255);
  border: gray solid 1px;
  /* width: 300px; */
  height: calc(100vh - 60px);
  z-index: 99;
}
.msg-body {
  background: rgb(241, 241, 241);
  padding: 15px 15px;
  height: calc(100% - 148px);
  overflow-x: hidden;
  overflow-y: scroll;
}
/*  隐藏滑动条 */
.msg-body::-webkit-scrollbar {
  width: 0 !important;
}
/*  //ie10+ */
.msg-body {
  -ms-overflow-style: none;
}
.msg-body {
  overflow: -moz-scrollbars-none;
}
.msg {
  display: flex;
}
.msg + .msg {
  margin-top: 8px;
}
.msg-content {
  // max-width: 265px;
  word-break: break-all;
  padding: 4px 8px 4px 8px;
  border-radius: 12px;
  font-size: 18px;
  text-align: left;
  background-color: #fff;
}
.noreversal {
  flex-direction: row;
}
.reversal {
  flex-direction: row-reverse;
}
.msg-foot {
  width: 100%;
  position: absolute;
  bottom: 0;
  display: flex;
  flex-direction: column;
  // padding: 5px 8px;
}
.sendbtn,.sendbtn:focus,.sendbtn:hover{
  border: unset;
  background-color: #152243;
  margin: 0 5px 5px 5px;
}
// 深度
::v-deep .el-input__inner{
  border: unset;
  border-radius: unset;
}
// 字数统计
::v-deep .el-input .el-input__count{
  position: absolute;
  bottom: -38px;
  right: 73px;
}
::v-deep .el-textarea .el-input__count{
  bottom: -38px;
  right: 73px;
}
::v-deep .el-textarea__inner{
  border: unset;
}
.inputcla{
  // height: 200px;
  // border: unset !important;
}
</style>
